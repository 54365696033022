// import $ from "jquery"; // JQUERY CAN BE REMOVED
import * as bootstrap from 'bootstrap';
import './assets/styles/main.scss';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

// console.log('app.js loaded');

window.addEventListener('DOMContentLoaded', event => {

    // Collapse responsive navbar when toggler is visible
    const navbarToggler = document.body.querySelector('.navbar-toggler');
    const responsiveNavItems = [].slice.call(
        document.querySelectorAll('#navbarResponsive .nav-link')
    );
    responsiveNavItems.map(function (responsiveNavItem) {
        responsiveNavItem.addEventListener('click', () => {
            if (window.getComputedStyle(navbarToggler).display !== 'none') {
                navbarToggler.click();
            }
        });
    });

    // Adds body class when user starts scrolling
  	$(window).scroll(function () {
  		var scroll = $(window).scrollTop();
  		var objectSelect = $("body");
  		var objectPosition = objectSelect.offset().top;
  		if (scroll > objectPosition) {
  			$("body").addClass("pd-scrolled");
  		} else {
  			$("body").removeClass("pd-scrolled");
  		}
  	});

    // Parallax header

    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function() {
        gsap.to(".parallax-header", {
          scrollTrigger: {
            scrub: true
          },
          y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
          ease: "none"
        });
      },
    });

  // Add active states to forms fields
  $('.hs-form input').each(function() {
    $(this).on('focus', function() {
      $(this).parent('.form-group').addClass('active');
    });
    $(this).on('blur', function() {
      // Zero out phone input
      // if($(this).val() == "(___) ___-____"){
      //   $(this).val('')
      // }
      if ($(this).val().length == 0) {
        $(this).parent('.form-group').removeClass('active');
      }
    });
    if ($(this).val() != '') $(this).parent('.css').addClass('active');
  });
  $('.hs-form textarea').each(function() {
    $(this).on('focus', function() {
      $(this).parent('.form-group').addClass('active');
    });
    $(this).on('blur', function() {
      if ($(this).val().length == 0) {
        $(this).parent('.form-group').removeClass('active');
      }
    });
    if ($(this).val() != '') $(this).parent('.css').addClass('active');
  });

  // Example starter JavaScript for disabling form submissions if there are invalid fields


  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation');

  $("#heritage-square-email-form").on("submit", function(event) {
    var form = $(this);
    event.preventDefault();
    event.stopPropagation();

    if (form[0].checkValidity() !== false) {
        var data = form.serialize();
        var formSubmit = form.find('button[type=submit]');
        formSubmit.attr('disabled', true);
        console.log({data});
        $.ajax({
        type : 'POST',
        url  : '/send_email.php',
        data : data,
        crossDomain : true,
        success :  function(data)
               {
               console.log({data});
               console.log('Success');
               // $('#heritage-square-email-form')[0].reset(); // this will empty the form back to error states. maybe consider hiding the form entirely or adding css to grey it out instead?
               $("#pd-success-message").css('display', 'block');
               $("#heritage-square-email-form").addClass('hs-form-submitted');
               },
        error: function (data) {
                // console.log(data);
                $("#pd-success-message").text('Error: ' + data.error);
            }
        });
    }
    form.addClass('was-validated');

  });

});
